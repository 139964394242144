export const BLOG_NAVIGATION_MENU = [
    { title: "ALL", category: "", s_type: "", search: "" },
    { title: "Kinetic", category: "", s_type: "author", search: "kinetic27" },
    { title: "General", category: "general", s_type: "", search: "" },
    { title: "BOJ", category: "BOJ", s_type: "", search: "" },
];

/*
    GET /api/blog?page=&size=&category=&search=&or&count

    page: number of page in post list
    size: number of blog posts to display on a single page

    category: blog posts by a specific category
    s_type: search type u want
    search: blog posts containing search word
    count: if count params exist, get articles count
*/
