import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Layout } from 'app/layout'
import { useTheme } from 'entities/theme'
import { Home } from 'pages/home'
import { Login } from 'pages/login'
import { Blog } from 'pages/blog';
import { BlogWrite } from 'pages/blogWrite';
import { ArticleDetail } from 'pages/article';
import { Profile } from 'pages/profile';
import { Fallback } from 'shared/ui/fallback'
import { getUser } from 'features/userAuth/api/getUser';
import { getBlogArticles, getBlogArticle, getBlogArticleWithAuthor } from 'features/blog';
import clsx from 'clsx'
import ProtectedRoute from "./protectedRoute";
import UnAuthedRoute from './unAuthedRoute';
import '../styles/index.scss'

export const AppRouter = () => {
  const { theme } = useTheme();

  const router = createBrowserRouter([
      {
          path: "/",
          id: "root",
          element: <Layout />,
          errorElement: <Fallback />,
          loader: async () => await getUser(),

          children: [
              {
                  index: true,
                  element: <Home />,
                  loader: async () => await getBlogArticles("?page=1&size=3"),
              },
              {
                  path: "b",
                  element: <Blog />,
                  loader: async ({ request }) =>
                      await getBlogArticles(undefined, true, "?" + request.url.split("?")[1]),
              },
              {
                  path: "b/:id",
                  element: <ArticleDetail />,
                  loader: async ({ params }) => await getBlogArticleWithAuthor(params.id),
              },
              {
                  path: "b/write",
                  element: <BlogWrite />,
              },
              {
                  path: "b/write/:cid",
                  element: <BlogWrite />,
                  loader: async ({ params }) => await getBlogArticle(params.cid),
              },
              {
                  element: <ProtectedRoute />,
                  children: [{ path: "u/profile", element: <Profile /> }],
              },
              {
                  element: <UnAuthedRoute />,
                  children: [{ path: "u/login", element: <Login /> }],
              },
              {
                  basename: process.env.PUBLIC_URL,
              },
          ],
      },
  ]);

  return (
    <div className={clsx('app', theme)}>
      <RouterProvider router={router} />
    </div>
  )
}