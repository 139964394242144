import { Helmet } from "react-helmet-async";

export const SEO = ({ title, description, image }) => {
    // console.log(title, description, image);
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:image" content={image} />
            <meta property="og:image:width" content="368" />
            <meta property="og:image:height" content="208" />
            <meta property="og:description" content={description} />
            <meta property="og:locale" content="ko_KR" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@kinetic" />
            <meta name="twitter:creator" content="@kinetic" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta property="twitter:domain" content="kinetic.moe" />
        </Helmet>
    );
}
