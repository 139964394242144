import clsx from "clsx";
import { useRouteLoaderData, useLoaderData, useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";

import "./blog.scss";
import { Article } from "features/blog";
import { ReactComponent as PlusIcon } from "shared/assets/icons/plus.svg";
import { ReactComponent as Magnify } from "shared/assets/icons/magnify.svg";
import { BLOG_NAVIGATION_MENU } from "shared/consts";
export const Blog = () => {
    const navigate = useNavigate();
    const user = useRouteLoaderData("root");
    const blogPerms = user?.permissions?.blog;

    const { page, size, count, data } = useLoaderData() || {};
    const [searchType, setSearchType] = useState("title_content");
    const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);

    // setting for pagination
    const Pagination = () => {
        const displayPerPage = 5;
        const totalPage = Math.ceil(count / size);
        const start = (Math.ceil(page / displayPerPage) - 1) * displayPerPage + 1;

        const inLastPagination = totalPage - (totalPage % displayPerPage) < page;

        const noPrev = page <= displayPerPage || !data;
        const noNext = inLastPagination || !data;

        const serachParams = new URLSearchParams(window.location.search);

        serachParams.set("page", start - 1);
        const prevLink = "?" + serachParams.toString();

        serachParams.set("page", start + displayPerPage);
        const nextLink = "?" + serachParams.toString();

        return (
            <ul className="pagenation">
                <li className={clsx("page", noPrev ? "hide" : "")}>
                    <Link to={prevLink}>{"<"}</Link>
                </li>
                {[...Array(displayPerPage)].map((_, i) => {
                    serachParams.set("page", start + i);

                    return (
                        start + i <= totalPage && (
                            <li key={i}>
                                <Link
                                    className={clsx("page", page === start + i ? "active" : "")}
                                    to={"?" + serachParams.toString()}
                                >
                                    {start + i}
                                </Link>
                            </li>
                        )
                    );
                })}
                <li className={clsx("page", noNext ? "hide" : "")}>
                    <Link to={nextLink}>{">"}</Link>
                </li>
            </ul>
        );
    };

    // category navigations
    const NavigationContent = (
        <ul className="blog__navigation-menu-list">
            {BLOG_NAVIGATION_MENU.map((item) => {
                const [title, category, s_type, search] = Object.values(item);

                const oldSearchParams = new URLSearchParams(window.location.search);
                const blogSearchParams = new URLSearchParams(window.location.search);

                // set search params
                category && blogSearchParams.set("category", category);
                s_type && blogSearchParams.set("s_type", s_type);
                search && blogSearchParams.set("search", search);

                if (!category) blogSearchParams.delete("category");
                if (!s_type) blogSearchParams.delete("s_type");
                if (!search) blogSearchParams.delete("search");

                // if page is exist, set page to 1
                if (blogSearchParams.get("page")) {
                    blogSearchParams.set("page", 1);
                    oldSearchParams.set("page", 1);
                }

                // if page is exist, set page to 1
                if (blogSearchParams.get("page")) blogSearchParams.set("page", 1);
                if (oldSearchParams.get("page")) blogSearchParams.set("page", 1);

                // add "?"" when search params exist
                let oldLink = oldSearchParams.toString();
                if (oldLink) oldLink = "?" + oldLink;

                let newLink = blogSearchParams.toString();
                if (newLink) newLink = "?" + newLink;

                const isSameQuery = newLink === oldLink;

                return (
                    <li key={title} className="blog__navigation-menu-item">
                        <Link
                            to={newLink}
                            className={clsx(
                                "blog__navigation-menu-link",
                                isSameQuery && "blog__navigation-menu-link_active"
                            )}
                        >
                            {title}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
    const { register, handleSubmit, setFocus } = useForm();

    const searchArticle = ({ search }) => {
        if (!search) {
            setFocus("search");
            return setIsSearchBoxOpen(!isSearchBoxOpen);
        }

        const blogSearchParams = new URLSearchParams(window.location.search);

        blogSearchParams.set("s_type", searchType);
        blogSearchParams.set("search", search);

        // if page is exist, set page to 1
        if (blogSearchParams.get("page")) blogSearchParams.set("page", 1);

        let newLink = blogSearchParams.toString();
        if (newLink) newLink = "?" + newLink;

        navigate(newLink);
    };

    const SearchBox = (
        <div className="blog__search-box">
            <div className={`blog__search-box__wrapper ${isSearchBoxOpen ? "open" : "closed"}`}>
                <select
                    className="blog__search-type_select"
                    onChange={(e) => setSearchType(e.target.value)}
                    value={searchType}
                >
                    <option value="title_content">&nbsp;제목/내용&nbsp;</option>
                    <option value="title">제목</option>
                    <option value="content">내용</option>
                    <option value="author">글쓴이</option>
                </select>

                <div className="blog__search-box__line" />

                <form className="editor__form" onSubmit={handleSubmit(searchArticle)}>
                    <input
                        {...register("search")}
                        className="blog__search-input"
                        type="text"
                        placeholder="검색어를 여기에 입력"
                    />
                </form>
            </div>

            <Magnify className="blog__search-icon" onClick={() => handleSubmit(searchArticle)()} />
        </div>
    );

    return (
        <div className="blog">
            <article className="layout__article">
                <div className="intro">
                    <h1 className="intro__title">BLOG</h1>
                    <h5 className="intro__subtitle">아무거나 재밌어보이면 해보는 블로그</h5>
                </div>

                <nav className={clsx("blog__navigation-menu-wrapper", "_container")}>
                    {NavigationContent}
                    {SearchBox}
                </nav>

                <ul className="articles">
                    {data &&
                        data.map((article) => (
                            <li key={article.id}>
                                <Article articleData={article} />
                            </li>
                        ))}
                </ul>

                {!data && (
                    <h1 className="no_article_text">
                        게시글이 하나도 없습니다.
                        <br />
                        새로운 게시물을 작성해보세요.
                    </h1>
                )}
            </article>

            <Pagination />

            {blogPerms?.write && (
                <div className="write_btn__wrapper">
                    <PlusIcon className="write_btn" onClick={() => navigate("/b/write")} />
                </div>
            )}
        </div>
    );
};
