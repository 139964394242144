import './articleAuthor.scss'

import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import support from 'shared/assets/images/support.png'
import accountOff from 'shared/assets/icons/account-off.png'

export const ArticleAuthor = ({ user }) => {
    const userImage = user?.image ?? accountOff;
    const userBio = user?.bio ?? 'User dose not exist.\nMaybe it\'s the user who left...';
    let userName = user?.name ?? 'Error,';
    userName = userName.length > 10 ? `${userName.slice(0, 10)}...` : userName

    const isOnwer = user?.id === 'kinetic27';

    const goToSupport = () => {
        if (isMobile) {
            window.open("https://qr.kakaopay.com/FLC6rDkXW1f403950", "_blank");
        }
    }

    const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);

    const updateMedia = () => setDesktop(window.innerWidth > 1024);

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return <div className='author__wrapper'>
        <div className='author_user_wrapper'>
            <img className='author_user_wrapper__img' src={userImage} alt='author'></img>
            <div className='author_user_wrapper__info'>
                <p className='author_user_wrapper__name'>{userName}</p>
                <textarea className='author_user_wrapper__bio' readOnly value={userBio}/>
            </div>
        </div>

        {isOnwer || !user?.bio
            ? <div className='author_support' onClick={goToSupport}>
                <img className="author_support__img" src={support} alt='author support' />
                <p className='author_support__info'>{!isDesktop && "클릭하여"} 커피 한 잔 사주기!</p>
            </div>
            : null}
    </div>
}