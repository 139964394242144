// import { ErrorBoundary } from 'react-error-boundary'
// import { Provider } from 'react-redux'
// import store, { persistor } from 'app/store'
// import { PersistGate } from 'redux-persist/integration/react'
// import { Fallback } from 'shared/ui/fallback'

import { ThemeProvider } from "entities/theme";
import { HelmetProvider } from "react-helmet-async";

export const Providers = ({ children }) => {
    return (
        // <ErrorBoundary FallbackComponent={Fallback}>
        //     <Provider store={store}>
        //         <PersistGate loading={null} persistor={persistor}>
        //             <ThemeProvider>{children}</ThemeProvider>
        //         </PersistGate>
        //     </Provider>
        // </ErrorBoundary>

        <HelmetProvider>
            <ThemeProvider>{children}</ThemeProvider>
        </HelmetProvider>
    );
};
