import "./article.scss";
import { useNavigate } from "react-router-dom";

export const Article = ({ articleData }) => {
    const { cid, category, creation_time, thumbnail, title, name, author } = articleData;
    const formattedTime = creation_time.split("T")[0].replaceAll("-", ".");
    const navigate = useNavigate();

    const serachParams = new URLSearchParams(window.location.search);

    serachParams.set("category", category);
    const categoryLink = "?" + serachParams.toString();

    return (
        <a className="article" href={`/b/${cid}`}>
            <img className="article__img" src={thumbnail} alt="article thumbnail"></img>
            <button
                className="article__category"
                onClick={(e) => {
                    e.preventDefault();
                    navigate(`/b/${categoryLink}`);
                }}
            >
                {category}
            </button>
            <h3 className="article__title">{title}</h3>
            <div className="article__info">
                <p className="article__author">{name || author}</p>
                <p className="article__time">{formattedTime}</p>
            </div>
        </a>
    );
};
