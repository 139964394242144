import "./articleMarkdownEditor.scss";

import { UploadImage } from "features/image";
import MDEditor from "@uiw/react-md-editor";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import remarkBreaks from "remark-breaks";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import rehypeInlineCodeClassName from "rehype-inline-code-classname";
import "katex/dist/katex.min.css";

export const ArticleMarkdownEditor = ({ imagePath = "", data, setData }) => {
    const insertToTextArea = (intsertString) => {
        const textarea = document.querySelector("textarea");
        if (!textarea) return null;

        let sentence = textarea.value;

        const front = sentence.slice(0, textarea.selectionStart);
        const back = sentence.slice(textarea.selectionStart, sentence.length);

        sentence = front + intsertString + back;

        textarea.value = sentence;
        textarea.selectionEnd = textarea.selectionEnd + intsertString.length;

        return sentence;
    };

    const UploadAndSetImage = async (data) => {
        const tmpText = data.getData("Text");
        const tmpHtml = data.getData("text/html");

        if (!tmpText && !tmpHtml) {
            const files = [];

            for (let index = 0; index < data?.items.length; index++) {
                const file = data.files.item(index);
                if (file) files.push(file);
            }

            const urls = await UploadImage(imagePath + "/", files);

            urls.forEach((e) => {
                if (e?.length !== 0) setData(insertToTextArea(`![](${e})`));
            });
        }
    };

    return (
        <div className="md__wrapper" data-color-mode="dark">
            <MDEditor
                value={data}
                onChange={setData}
                preview="edit"
                onPaste={async (event) => {
                    UploadAndSetImage(event.clipboardData);
                }}
                onDrop={async (event) => {
                    event.preventDefault();
                    UploadAndSetImage(event.dataTransfer);
                }}
                height="100%"
                // minHeight={50}
                visibleDragbar={false}
                previewOptions={{
                    rehypePlugins: [[rehypeKatex, rehypeRaw, rehypeInlineCodeClassName]],
                    remarkPlugins: [[remarkGfm, remarkMath, remarkBreaks]],
                }}
                textareaProps={{
                    placeholder: "여기에 무언가를 입력해보세요.",
                }}
            />
        </div>
    );
};
