import { API } from "shared/api";

export const getBlogArticleWithAuthor = async (id) => {
    let article, user;

    try {
        article = await API.get(`/blog/${id}`);
        user = await API.get(`/auth/profile/${article.data.author}`);

        return { ...article.data, ...user.data };
    } catch (err) {
        // if the user does not exist, return only article.data
        if (err.request.responseURL.startsWith(`${API.defaults.baseURL}/auth/profile/`))
            return article.data;
        
        throw err;
    }
}