import React from "react";
// import ReactDOM from "react-dom/client";
import { createRoot, hydrateRoot } from "react-dom/client";
import App from "./app/App";

import "./index.scss";

const rootElement = document.getElementById("root");
// root.render(
//     // <React.StrictMode>
//     <App />
//     // </React.StrictMode>
// );

if (rootElement.hasChildNodes()) {
    hydrateRoot(rootElement, <App />);
} else {
    createRoot(rootElement).render(<App />);
}

// https://micalgenus.github.io/articles/2019-03/React-snap-puppeteer-no-sandbox/
