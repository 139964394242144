import { useRouteLoaderData, useNavigate, useLoaderData } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./blogWrite.scss";
import { useEffect, useState, useRef } from "react";
import { ArticleMarkdown } from "features/blog/ui/articleMarkdown";
import { ArticleMarkdownEditor } from "features/blog/ui/articleMarkdownEditor";
import { UploadImage } from "features/image";
import { postBlogArticle, editBlogArticle, deleteBlogArticle } from "features/blog";
import { toast } from "react-toastify";
import crypto from "crypto-js";
import clsx from "clsx";
import { HexColorPicker } from "react-colorful";

import { ReactComponent as UploadIcon } from "shared/assets/icons/upload.svg";
import { ReactComponent as DownloadIcon } from "shared/assets/icons/download.svg";

export const BlogWrite = () => {
    const user = useRouteLoaderData("root");
    const article_data = useLoaderData();
    const editmode = article_data !== undefined;

    const navigate = useNavigate();

    const blogPerms = user?.permissions?.blog;

    useEffect(() => {
        if (!user) {
            alert("로그인이 필요합니다.");
            return navigate("/u/login");
        }

        if (!blogPerms?.write && !blogPerms?.admin) {
            alert("페이지 열람 권한이 없습니다.");

            return navigate(-1);
        }
    }, [user, navigate, blogPerms, editmode]);

    const encryptArticleId = (id) => {
        return crypto.AES.encrypt(
            process.env.REACT_APP_ARTICLE_SECRET_PREFIX + id + process.env.REACT_APP_ARTICLE_SECRET_SUFFIX,
            process.env.REACT_APP_ARTICLE_SECRET
        ).toString();
    };

    const decryptArticleId = (id) => {
        try {
            let result = crypto.AES.decrypt(id, process.env.REACT_APP_ARTICLE_SECRET).toString(crypto.enc.Utf8);

            result = result
                .replace(process.env.REACT_APP_ARTICLE_SECRET_PREFIX, "")
                .replace(process.env.REACT_APP_ARTICLE_SECRET_SUFFIX, "");

            if (isNaN(result)) return "";

            return result;
        } catch (e) {
            return "";
        }
    };

    const [articleId, setArticleId] = useState(0);

    const [textValue, setTextValue] = useState("");
    const { register, handleSubmit, setFocus, setValue, getValues } = useForm();
    const thumbnail_image = useRef();

    const [canUpload, setCanUpload] = useState(true);
    const [isActive, setActive] = useState(false);

    const [thumbnailBackgroundColor, setThumbnailBackgroundColor] = useState("#000000");
    const [thumbnailTitlecolor, setThumbnailTitleColor] = useState("#ffffff");

    useEffect(() => {
        setTimeout(() => setFocus("id"), 500);

        let tempArticleId = window.localStorage.getItem("tempArticleId");
        tempArticleId = decryptArticleId(tempArticleId);

        if (!tempArticleId) {
            tempArticleId = new Date().getTime() + "";
            window.localStorage.setItem("tempArticleId", encryptArticleId(tempArticleId));
        }

        setArticleId(tempArticleId);

        if (editmode) {
            const { id, category, content, title, thumbnail, cid, author } = article_data;

            if (blogPerms?.write && user.id !== author && !blogPerms?.admin) {
                alert("글 수정 권한이 없습니다.");

                return navigate(-1);
            }

            setArticleId(id);
            setValue("title", title);
            setValue("cid", cid);
            setValue("category", category);
            setValue("thumbnail_link", thumbnail);
            thumbnail_image.current.src = thumbnail;
            setCanUpload(false);
            setTextValue(content);
        } else {
            const tempTitle = window.localStorage.getItem("tempTitle");
            const tempThumbnail = window.localStorage.getItem("tempThumbnail");
            const tempCid = window.localStorage.getItem("tempCid");
            const tempCategory = window.localStorage.getItem("tempCategory");
            const tempContent = window.localStorage.getItem("tempContent");

            tempTitle && setValue("title", tempTitle);
            tempCid && setValue("cid", tempCid);
            tempCategory && setValue("category", tempCategory);
            tempThumbnail && setValue("thumbnail_link", tempThumbnail);
            thumbnail_image.current.src = tempThumbnail;
            tempContent && setTextValue(tempContent);

            if (tempThumbnail) {
                setCanUpload(false);
            }
        }
    }, [article_data, editmode, navigate, user, blogPerms, setTextValue, setFocus, setValue]);

    const submitArticle = async (data) => {
        data["text"] = textValue;
        data["id"] = encryptArticleId(articleId);
        data["thumbnail"] = data["thumbnail_link"];
        delete data["thumbnail_link"];

        if (!textValue) {
            toast.error("본문이 비어있습니다.", { toastId: "article_no_content" });

            return;
        }

        if (!data?.thumbnail) {
            const firstImg = /!\[.*\]\((?<link>.*)\)/.exec(textValue);

            if (firstImg?.groups.link) {
                setValue("thumbnail_link", firstImg?.groups.link);
                toast.info("첫 이미지를 썸네일로 설정했습니다.", {
                    toastId: "article_set_first_img_to_thumbnail",
                });
            } else
                toast.error("썸네일로 사용할 이미지를 본문에서 찾을 수 없습니다.", {
                    toastId: "article_set_first_img_err",
                });

            return;
        }

        if (editmode) {
            const result = await editBlogArticle(data);

            if (result.isSuccess) {
                navigate("/b/" + decryptArticleId(data.id));
            } else {
                toast.error(
                    <>
                        글 수정을 실패했습니다
                        <br />
                        {result.data?.response?.data.details[0]?.message}
                    </>,
                    { toastId: "article_edit_false" }
                );
            }
        } else {
            const result = await postBlogArticle(data);

            if (result.isSuccess) {
                navigate("/b/");

                window.localStorage.removeItem("tempArticleId");
                window.localStorage.removeItem("tempTitle");
                window.localStorage.removeItem("tempCid");
                window.localStorage.removeItem("tempCategory");
                window.localStorage.removeItem("tempThumbnail");
                window.localStorage.removeItem("tempContent");
            } else {
                toast.error(
                    <>
                        글 쓰기를 실패했습니다
                        <br />
                        {result.data?.response?.data.details[0]?.message}
                    </>,
                    { toastId: "article_post_false" }
                );
            }
        }
    };

    const deleteArticle = async () => {
        if (window.confirm("정말로 삭제하시겠습니까?")) {
            const result = await deleteBlogArticle({
                data: { id: article_data.id },
            });

            if (result.isSuccess) navigate("/b/");
            else
                toast.error(
                    <>
                        글 삭제를 실패했습니다
                        <br />
                        {result.data?.response?.data}
                    </>,
                    { toastId: "article_delete_false" }
                );
        }
    };

    const resetArticle = () => {
        if (window.confirm("정말로 초기화하시겠습니까?")) {
            window.localStorage.removeItem("tempTitle");
            window.localStorage.removeItem("tempCid");
            window.localStorage.removeItem("tempCategory");
            window.localStorage.removeItem("tempThumbnail");
            window.localStorage.removeItem("tempContent");

            window.location.reload();
        }
    };

    const UploadAndSetImage = async (data) => {
        const tmpText = data.getData("Text");
        const tmpHtml = data.getData("text/html");

        if (!tmpText && !tmpHtml) {
            const files = [];

            for (let index = 0; index < data?.items.length; index++) {
                const file = data.files.item(index);
                if (file) files.push(file);
            }

            const url = await UploadImage(articleId + "/", files);

            setValue("thumbnail_link", url[0]);
            thumbnail_image.current.src = url[0];
            window.localStorage.setItem("tempThumbnail", url[0]);
        }
    };

    const UploadLogo = (props) => (
        <svg {...props} x="0px" y="0px" viewBox="0 0 24 24">
            {/* <path fill="transparent" d="M0,0h24v24H0V0z" /> */}
            <path d="M20.5,5.2l-1.4-1.7C18.9,3.2,18.5,3,18,3H6C5.5,3,5.1,3.2,4.8,3.5L3.5,5.2C3.2,5.6,3,6,3,6.5V19  c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V6.5C21,6,20.8,5.6,20.5,5.2z M12,17.5L6.5,12H10v-2h4v2h3.5L12,17.5z M5.1,5l0.8-1h12l0.9,1  H5.1z" />
        </svg>
    );

    const ExportArticle = () => {
        console.log(getValues());
        const { title, cid, category, thumbnail_link } = getValues();

        const exportData = {
            title,
            cid,
            category,
            thumbnail_link,
            text: textValue,
        };

        const dateString = new Date().toISOString().split("T")[0];

        const fileData = JSON.stringify(exportData);
        const blob = new Blob([fileData], { type: "text/plain" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.download = `article_${articleId}_${dateString}.json`;
        link.href = url;
        link.click();
    };

    const ImportArticle = async ({ target }) => {
        const file = target.files[0];
        const reader = new FileReader();

        reader.onload = async (event) => {
            if (!window.confirm("글을 불러오면 이전에 작성하던 글이 사라집니다.")) return;

            const data = JSON.parse(event.target.result);

            setValue("title", data.title);
            setValue("cid", data.cid);
            setValue("category", data.category);
            setValue("thumbnail_link", data.thumbnail_link);
            setTextValue(data.text);

            window.localStorage.setItem("tempTitle", data.title);
            window.localStorage.setItem("tempCid", data.cid);
            window.localStorage.setItem("tempCategory", data.category);
            window.localStorage.setItem("tempThumbnail", data.thumbnail_link);
            window.localStorage.setItem("tempContent", data.text);

            thumbnail_image.current.src = data.thumbnail_link;
            setCanUpload(false);

            toast.info("불러오기 완료", { toastId: "article_import_success" });
        };

        reader.readAsText(file);
    };

    const generateThumbnail = () => {
        const text = document.getElementById("thumbnail_title").value || article_data?.title || "썸네일";
        const textSize = document.getElementById("thumbnail_title_size").value || "80";

        const canvas = document.getElementById("thumbnail_canvas");
        const ctx = canvas.getContext("2d");

        ctx.fillStyle = thumbnailBackgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.fillStyle = thumbnailTitlecolor;
        ctx.textBaseline = "middle";
        ctx.font = `bold ${textSize}px sans-serif`;
        ctx.textAlign = "center";
        ctx.fillText(text, canvas.width / 2, canvas.height / 2);
    };

    const ThumnailGenerator = (
        <div className="editor__thumbnail_generator">
            <canvas id="thumbnail_canvas" width="768" height="400" />
            <div className="editor__thumbnail_generator_wrapper">
                <div>
                    <label htmlFor="thumbnail_title">텍스트</label>
                    <input
                        type="text"
                        id="thumbnail_title"
                        className="editor__input editor__thumbnail_generator_input"
                        placeholder="텍스트를 입력하세요"
                        defaultValue={getValues("title")}
                        onChange={generateThumbnail}
                    />
                </div>
                <div>
                    <label htmlFor="thumbnail_title_size">텍스트 사이즈</label>
                    <input
                        type="range"
                        id="thumbnail_title_size"
                        className="editor__input editor__thumbnail_generator_input"
                        defaultValue="80"
                        min="0"
                        max="250"
                        onChange={generateThumbnail}
                    />
                </div>

                <div className="editor__thumbnail_colorpicker_wrapper">
                    <HexColorPicker
                        color={thumbnailBackgroundColor}
                        onChange={(color) => {
                            setThumbnailBackgroundColor(color);
                            generateThumbnail();
                        }}
                    />

                    <HexColorPicker
                        color={thumbnailTitlecolor}
                        onChange={(color) => {
                            setThumbnailTitleColor(color);
                            generateThumbnail();
                        }}
                    />
                </div>

                <button
                    className="editor__thumbnail_apply editor__btn"
                    type="button"
                    onClick={() => {
                        const canvas = document.getElementById("thumbnail_canvas");
                        const url = canvas.toDataURL("image/png");

                        setValue("thumbnail_link", url);
                        thumbnail_image.current.src = url;
                        setCanUpload(false);

                        window.localStorage.setItem("tempThumbnail", url);
                    }}
                >
                    적용하기
                </button>
            </div>
        </div>
    );

    return (
        <div className="editor">
            <article className="layout__article">
                <div className="editor__header">
                    <p className="editor__article_id">{articleId.toString()}</p>

                    <div className="editor__backup_wrapper">
                        <UploadIcon className="editor__backup_upload" onClick={ExportArticle} />

                        <label className="editor__import_article">
                            <input
                                name="thumbnail"
                                type="file"
                                accept="application/JSON"
                                onChange={ImportArticle}
                                style={{ display: "none" }}
                            />
                            <DownloadIcon className="editor__backup_download" />
                        </label>
                    </div>
                </div>

                <form className="editor__form" onSubmit={handleSubmit(submitArticle)}>
                    <input
                        {...register("title")}
                        name="title"
                        className="editor__input editor__title"
                        type="text"
                        placeholder="제목"
                        spellCheck="false"
                        maxLength="30"
                        onChange={({ target }) => !editmode && window.localStorage.setItem("tempTitle", target.value)}
                        required
                    />

                    <ArticleMarkdownEditor
                        imagePath={articleId + "/"}
                        data={textValue}
                        setData={(data) => {
                            !editmode && window.localStorage.setItem("tempContent", data);
                            setTextValue(data);
                        }}
                    />

                    <div className="editor__subinfo_wrapper">
                        <input
                            {...register("cid")}
                            name="cid"
                            className="editor__input editor__cid"
                            type="text"
                            placeholder="제목 URL"
                            spellCheck="false"
                            maxLength="30"
                            pattern="^[a-zA-Z0-9\-_.~]+$"
                            defaultValue={`article-${Number(articleId).toString(36)}`}
                            onChange={({ target }) => !editmode && window.localStorage.setItem("tempCid", target.value)}
                            required
                        />

                        <input
                            {...register("category")}
                            name="category"
                            className="editor__input editor__category"
                            type="text"
                            placeholder="카테고리"
                            spellCheck="false"
                            maxLength="30"
                            defaultValue="general"
                            onChange={({ target }) =>
                                !editmode && window.localStorage.setItem("tempCategory", target.value)
                            }
                            required
                        />
                    </div>

                    <div
                        className={clsx(
                            "editor__thumbnail_wrapper",
                            "editor__input",
                            `editor__thumbnail_preview_${isActive ? "active" : "inactive"}`
                        )}
                    >
                        <input
                            {...register("thumbnail_link")}
                            name="thumbnail_link"
                            className="editor__input editor__thumbnail"
                            type="text"
                            placeholder="썸네일 링크 (외부링크 혹은 직접 업로드)"
                            spellCheck="false"
                            pattern="(https:\/\/.*)|(data:image\/png;base64,.*)"
                            onChange={({ target }) => {
                                !editmode && window.localStorage.setItem("tempThumbnail", target.value);
                                setCanUpload(target.value ? false : true);
                                if (target.value) thumbnail_image.current.src = target.value;
                            }}
                        />

                        <label
                            className="editor__thumbnail_label"
                            onDragEnter={() => setActive(true)}
                            onDragLeave={() => setActive(false)}
                            onDrop={async (event) => {
                                event.preventDefault();
                                UploadAndSetImage(event?.dataTransfer);
                                canUpload(false);
                            }}
                        >
                            <input
                                {...register("thumbnail")}
                                name="thumbnail"
                                className="editor__input"
                                type="file"
                                accept="image/*"
                                onChange={async ({ target }) => {
                                    const url = await UploadImage(articleId + "/", Array.from(target.files));

                                    !editmode && window.localStorage.setItem("tempThumbnail", url[0][0]);
                                    setValue("thumbnail_link", url[0][0]);
                                    setCanUpload(false);
                                    thumbnail_image.current.src = url[0][0];
                                }}
                                style={{ display: "none" }}
                            />

                            {canUpload && (
                                <>
                                    <UploadLogo className="editor__thumbnail_logo" />
                                    <p className="preview_msg">클릭하거나 파일을 이곳에 드롭</p>
                                </>
                            )}

                            <img
                                ref={thumbnail_image}
                                className={clsx(
                                    "editor__thumbnail_image",
                                    `editor__thumbnail_${canUpload ? "inactive" : "active"}`
                                )}
                                onError={() => setCanUpload(true)}
                                src=""
                                alt="썸네일 이미지"
                            />
                        </label>
                    </div>

                    <details>
                        <summary onClick={generateThumbnail}>썸네일 생성기</summary>
                        {ThumnailGenerator}
                    </details>

                    <details>
                        <summary>블로그 적용 미리보기</summary>
                        <ArticleMarkdown data={textValue} />
                    </details>

                    <div className="editor__submit_wrapper">
                        {editmode && (
                            <>
                                <button className="editor__delete editor__btn" type="button" onClick={deleteArticle}>
                                    삭제하기
                                </button>

                                <button className="editor__edit editor__btn" type="submit">
                                    수정하기
                                </button>
                            </>
                        )}

                        {!editmode && (
                            <>
                                <button className="editor__delete editor__btn" type="button" onClick={resetArticle}>
                                    초기화
                                </button>

                                <button className="editor__submit editor__btn" type="submit">
                                    작성하기
                                </button>
                            </>
                        )}
                    </div>
                </form>
            </article>
        </div>
    );
};
